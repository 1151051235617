import './index.scss';
let jquery = require('./node_modules/jquery/dist/jquery');
window.$ = window.jQuery = jquery;
import popper from 'popper.js';
import bootstrap from 'bootstrap';

$(document).ready(function() {
    pannellum.viewer('panorama', {
        autoLoad: true,
        type: 'equirectangular',
        panorama: $('#panorama').attr('data-image')
    });
});
